import ready from "../../components/_ready";
import Swal from 'sweetalert2'

ready(() => {
  
  let message = $('#alert-message').val();
  const title = $('#alert-title').val();
  const type = $('#alert-type').val();
  let msg = $('.alertNewVenue').val();
  let value = "";
  $(".alertNewVenue").each(function() {
     value += ` ${$(this).val()} <br>`;
  });

  if(value != ""){
    message = value;
  }
  console.log(message);
  const validationErrors = $('.validation-message');

  if (message && title && type) {
    fireAlert(title, message, type);
  } else if (validationErrors.length > 0 && !message && title && type) {
    const fullMessage = getValidationErrorsMessage(validationErrors);
    fireAlert(title, fullMessage, type);
  }

  function fireAlert(title, message, type) {
    Swal.fire({
      title: title,
      html: message,
      type: type,
      icon: type,
      showConfirmButton: false
    });
  }

  function getValidationErrorsMessage(validationErrors) {
    let fullMessage = '';

    $.each(validationErrors, (index, element) => {
      // if it's the last validation message
      if (index === validationErrors.length - 1) {
        fullMessage += `- ${element.value} `;
      } else {
        fullMessage += `- ${element.value} \n`;
      }
    });

    return fullMessage;
  }
});
